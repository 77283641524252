.home_products {
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    height: auto;
    overflow: hidden;

    box-sizing: border-box;

}

.home_head {

    width: 100%;
    height: 100%;
    position: absolute;
    top: 60px;
    overflow: hidden;
}

.home_head_background {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 250px; /* Minimum height to ensure content is visible */
    background-color: var(--main-color-3);
    z-index: 1;
    overflow: hidden;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
/*    mask-image: linear-gradient(to top, transparent, var(--main-color-3) 10%);*/
}
@media (min-width: 1024px) {

    .home_head_background {
        height: 550px;

    }
}

.home_head_video {
    position: absolute;
    /*top: 0;*/
    left: 0;
    opacity: 100%;
    width: 100%;
    /*height: 100%;*/
    object-fit: fill;
    z-index: -1;
}

.background_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: transform 0.1s;
}

.home_head_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 20px;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Semi-transparent black overlay */
    z-index: 2;
}

/* Other styles remain the same */


.contact_us {
    width: 100%;
    min-width: 350px;
    max-width: 500px;
    height: 100%;
    max-height: 300px;
    color: white;
    margin: 40px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
padding: 10px;
}

.contact_us_text {
    width: 100%;
    height: auto;

    text-align: center;
    font-size: 30px;
    margin-bottom: 40px;
    font-family: "fontR", sans-serif;
}
.contact_us_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.contact_us_container input,textarea{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding: 10px;
    font-family: "fontB", sans-serif;
    background-color: var(--main-color-3);
    border-style: none;
    outline: none!important;
    color: white;
    margin-top: 10px;
}
.contact_us_container textarea{

    height: 150px;

}
.main_block:hover {
    transform: scale(1.04);  /* Увеличиваем размер при наведении */
    opacity: 0.9;  /* Слегка уменьшаем непрозрачность */
}
.main_block::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.29); /* Полупрозрачный черный */
}
.contact_us_container_main{
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact_us_container_main input{
    margin: 5px;
}

.contact_us_container_sec{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}


.main_blocks_container {
   overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    height: auto;

    z-index: 100000;
    padding-bottom: 150px;
    position: relative;


    margin: 0 auto;
}
.main_blocks_container2 {

    width: 100%;
    height: 100%;
   padding-bottom: 80px;
    z-index: 100000;
    overflow: hidden;


    margin: 0 auto;
}
.home_head_logo {
    position: absolute;


    color:  var(--main-color-1);
    font-size: 40px;
    z-index: 100000000;
}

.main_blocks_text {
    padding: 50px;
    margin-top: 0px;
    text-align: center;
    font-family: "fontR", sans-serif;
    font-size: 2.4em;
    color:  var(--main-color-1);
}
.weeekend_development {
    padding: 50px;
    margin-bottom: -50px;
    text-align: center;
    font-family: "fontR", sans-serif;
    font-size: 1em;
    color:  var(--main-color-1);
    /*background-image: url(https://weeekend.me);*/
}

.main_blocks {
    max-width: 1200px;

    margin: 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}
.main_blocks2 {
    max-width: 1200px;
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}
.main_blocks2 {
    max-width: 1200px;
    padding: 10px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

.main_active {

    overflow: hidden;
    display: flex;
    align-items: center;
    position: fixed;
    font-family: "fontB", sans-serif;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    background-color: var(--main-color-3);
    z-index: 100000000000000000000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main_active_container .logo {
    width: 200px;
    height: auto;
    position: absolute;
    top: 30px;
}

.main_cative_img {
    margin: 0 auto;
    text-align: center;
    width: 300px;

}

.main_active_text {
    width: 80%;
}

.main_active_text h2 {
    text-align: center;
    font-size: 23px;
    font-family: "fontB", sans-serif;

    margin-top: 20px;
    color: var(--main-color-1);
}

.main_active_text h3 {
    text-align: center;
    margin-top: 5px;
    font-size: 19px;
    font-family: "fontR", sans-serif;


    color: var(--main-color-1);
}

.main_active_input {
    background-color: var(--main-color-1) !important;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    color: var(--main-color-2) !important;
    cursor: pointer;
    font-family: fontB, sans-serif;
    font-size: 18px;
    height: 55px;
    margin-bottom: 10px;
    margin-top: 10px;
    outline: none;
    padding: 0 20px;
    width: 100%;
    max-width: 350px;
}


.main_active_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main_language_container {
    display: flex;
    width: 100%;
    max-width: 300px;

    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.language_item {
    border-radius: 10px;
    width: 100px;
    height: 56px;
    overflow: hidden;
    position: relative;
    margin: 10px 10px 20px 10px;
}

.language_item img {
    cursor: pointer;
    width: 100px;

}

.main_block {
    cursor: pointer;
    /*background-size: contain;*/
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--main-color-6);
    text-align: center;
    width: 100%;
    max-width: 371px;
    height: 256px;
    border-radius: 20px;
    margin: 11px;
    display: block;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    mix-blend-mode: multiply;
    position: relative;
    transition: transform 0.5s ease, opacity 0.5s ease;
    transform: translateY(15px);
}
.ml1,.ml4,.ml5,.ml7{

}

.ml2,.ml3,.ml6{

}


.overlay-text {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%); /* Центрируем текст */
    color: white;
    font-size: 42px;
    font-weight: bold;
    text-align: center;
    /*background: rgba(0, 0, 0, 0.3); !* Затемненный фон для читаемости *!*/
    /*padding: 10px 20px;*/
    border-radius: 8px;
    z-index: 1000;
    white-space: nowrap;

}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
.main_block2 {
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-color: var(--main-color-6) !important;
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 250px;
    border-radius: 20px;
    margin: 5px;
}

.main_block2_text{
    width: 100%;

    position: absolute;
    bottom: 20px;
    text-align: left;
    font-size: 40px;
    left: 20px;
    height: auto;
    font-weight: 700;
    color: white;
    z-index: 10000;
    font-family: "fontS", sans-serif;
}

.home_head_text {
    display: none!important;
    z-index: 2;
    text-align: center;
    color: white;
    padding: 15px 15px 30px 15px;
    max-width: 500px;
   font-family: "fontR", sans-serif;
    margin: auto;
    font-size: 16px;
}
.home_head_img{
    background-image: url("../img/kinotel.jpg");
    background-position: center;
    justify-content: center;
    align-items: center;
    background-size: cover;
border-radius: 10px;
    width: 350px;
    height: 200px;
   z-index: 1000000000;
}
.kinotel_head{
    z-index: 100000000000000;

    overflow: hidden;
    margin: 0 auto;


    flex-wrap: wrap;


    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}
.kinotel_head_text {
    font-family: fontR, sans-serif;
  margin-top: 40px;
    width: 100%;
    height: auto;
    z-index: 10000000;
    max-width: 400px;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
}
.kinote_head_container{
    width: 100%;
    margin-top: 80px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.Product_images img {
    height: 500px;
    width: auto;
}
.fullscreen-overlay {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000000000000000000;
}
.fullscreen-overlay2 {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    justify-content: center;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 10000000000000000000;
}
.fullscreen-image-container {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.close-button {
    align-items: center;
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    height: 40px;
    justify-content: center;
    right: 20px;
    top: 20px;
    width: 40px;
    z-index: 10000000000000000000;
}
.button2 {

    top: 80px!important;
    width: 40px;
    z-index: 10000000000000000000;
}
.next-button, .prev-button {
    background-color: #0003;
    border-radius: 10px;
    display: flex;
    height: 100px;
    justify-content: center;
    position: absolute;
    width: 50px;
    z-index: 1000000000;
}

.next-button {
    right: 0;
}
.prev-button {
    left: 0;
}
.next-button svg, .prev-button svg ,.close-button svg{
    stroke: #fff;
}
.close-button, .next-button, .prev-button {
    position: absolute;
    z-index: 1000000000;
}
.fullscreen-image-container img {
    width: 100%;
}
.Product_images {
    align-items: center;
    background-color: transparent!important;
    border-radius: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    height: 300px;
    max-width: 1000px;
    object-fit: cover;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}


.description_text{
    width: 100%;
    margin: 100px auto;
    padding: 10px;
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   position: relative;
    overflow: hidden;


}
.description_main_text{
    width: 100%;
    height: auto;
    padding: 10px;
    font-family: "fontA", sans-serif;
    color: white;

font-size: 30px;
}


.ORDER_conatiner.active {
    height: 300px !important;
}

#nortific {
    font-family: "fontB", sans-serif !important;


}



.ORDER_conatiner_ITEMS.active{
    height: 300px!important;
    margin: 30px auto;
}

.nortific.active{
    display: none!important;
}

.ORDER_form.active{
    display: none!important;
}

.nortific{
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.nortific a{
    width: 100%;
}
.product_description_button, .products_conatiner_back_button {

    cursor: pointer;
    font-family: fontB,sans-serif;
}
.products_conatiner_back_button {
    align-items: center;
    background-color: var(--main-color-3) !important;
    display: flex;
    font-size: 15px;
    justify-content: center;
    margin-bottom: -10px;


    position: absolute;
    top: 65px;
    width: 100px;
}
.products_conatiner_back_button {
    overflow: hidden;
    z-index: 100000000;
}
.nortific svg{
    width: 70px;
    height: 70px;
    background-color: var(--main-color-6);
    padding: 10px;
    border-radius: 10px;
    color: white;
}

.nortific h1{
    color: white;
    font-family: "fontR", sans-serif;
    margin-top: 20px;
    font-size: 20px;
}
.nortific h2{
    font-family: "fontR", sans-serif;
    font-size: 15px;
    margin-top: 5px;
    color: white;
}
.nortific button{
    background-color: var(--main-color-6) !important;
    border-radius: 10px;
    border-style: none;

    color: #fff;
    cursor: pointer;
    font-family: fontB, sans-serif;
    height: 50px;
    left: 50%;
    letter-spacing: 1px;

    text-transform: uppercase;
    margin: 20px auto;
    width: 95%;

}
.main_blocks_sec_text{
    width: 100%;
    height: auto;
    font-family: "fontR", sans-serif;
    color: white;
     padding: 10px;
    margin-top: 20px;
    font-size: 15px;
}

.product_description_button {
    background-color: white;
    border-style: none;
    color: black;
    height: 50px;
    letter-spacing: 1px;
    max-width: 350px;
    margin-top: 20px;
    text-transform: uppercase;
    width: 100%;
    z-index: 1600;
}


.react-datepicker, .react-datepicker__header {
    color: white!important;
    padding: 10px;
    border: none!important;
    background-color: var(--main-color-2) !important;
}
.react-datepicker__week{
    display: flex;
    justify-content: space-between;
}
.react-datepicker__month{
    width: 350px;
    margin: 0 auto;

}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    color: white!important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    color: white!important;
}
.react-datepicker__navigation--previous{
    left: 50px!important;
    top: 10px!important;
}
 .react-datepicker__time-name{
    color: #1a1919!important;
}

.react-datepicker__navigation--next{
    right: 50px!important;
    top: 10px!important;
}
.date_calc{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.nadstrok{
    margin-bottom: 10px;
}
.dates{
    width: 100%;
    height: 100%;
    display: flex;
    margin: 20px auto;
    align-items: center;
    justify-content: center;
}
.dates_text {
    align-items: center;
    display: flex;
    font-family: fontR,sans-serif;
    font-size: 15px;
    height: 50px;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.react-datepicker__day--disabled {
    color: #505050 !important;
}
.react-datepicker__day--keyboard-selected{
    background-color: #777777!important;
}


.days_count, .min_count {
    align-items: center;
    background-color: var(--main-color-6) !important;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-family: fontR,sans-serif;
    justify-content: space-between;
    padding: 10px;
}
.days_count {
    width: 120px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: fontR,sans-serif;
    font-size: 18px;
    height: 80px;
    flex-direction: column;
}
.days_count, .days_count .date_text_i {
    color: #fff !important;
}
.ORDER_INPUTAS{
    font-family: "fontR", sans-serif;
    color: white;
    font-size: 20px;
    width: 100%;
}
.NAME_INPUTS,.INDIVID_INPUTAS{
    font-family: "fontR", sans-serif;
    color: white;
    font-size: 20px;
    margin: 10px;
    width: 100%;
    max-width: 350px;
}

.booking_inputs{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
}

.ORDER_NUMBER_INPUTAS {
    background-color: var(--main-color-3) !important;
    border-radius: 10px;
    border-style: none !important;
    color: var(--main-color-1) !important;
    font-family: fontR,sans-serif;
    font-size: 20px;
    height: 50px;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
}
.date_i{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #1a1919;
    font-family: "fontB", sans-serif;
    border-radius: 10px;
    margin-top: 5px;
}

.date_text_i{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.date {
    align-items: start;
    background-color: var(--main-color-3) !important;
    border-radius: 10px;
    color: var(--main-color-1) !important;
    display: flex;
    flex-direction: column;
    font-family: fontR,sans-serif;
    padding: 10px;
    max-width: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: none;
    width: 250px;
    height: 250px;
    font-family: "fontB", sans-serif;
    font-size: 15px;
    line-height: 2rem;
    letter-spacing: 2px;
}


.react-datepicker__day--selected {
    background-color: white !important;
    color: #1a1919!important;
    text-align: center;
}
.react-datepicker__day--in-range{
    background-color: #ffffff !important;
    color: #1a1919!important;
}


.ru1 {
    background-image: url(../img/rooms/1.PNG);
}
.ru2 {
    background-image: url(../img/rooms/2.png);
}
.ru3 {
    background-image: url(../img/rooms/3.png);
}
.ru4 {
    background-image: url(../img/rooms/4.png);
}
.ru5 {
    background-image: url(../img/rooms/5.png);
}
.ru6 {
    background-image: url(../img/rooms/6.png);
}
.ml1 {
    background-color: #424083;
    background-image: url(../img/nadimi.jpg);
}


.ml2 {
    background-color: #EBE1D9;
    background-image: url(../img/copacabana.jpg);
}

.ml3 {
    background-color: #211E1F;
    background-image: url(../img/aromiitaliani.jpg);
}

.ml4 {
    background-color: #ffffff;
    background-image: url(../img/poshnosh.jpg);
}

.ml5 {
    background-color: #ffffff;
    background-image: url(../img/symphosium.jpg);
}

.ml6 {
    background-color: black;
    background-image: url(../img/ml6.png);
}
.ml7 {
    background-color: #ffffff;
    background-image: url(../img/cave.jpg);
}
.m18 {
    background-color: #000000;
    width: 120%;
    background-image: url(../img/ml18.png);

}
.main_text {

    height: 120px;
    margin: 10px auto;
    position: relative;
    z-index: 100000000;
    color: var(--main-color-2) !important;
    font-family: "fontB", sans-serif;
    font-size: 13px;
}

.main_content {

    height: 130px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main_block img {
    position: absolute;
    width: 170px;
    margin-top: 45px;
    cursor: pointer;
    margin-left: -20px;

}
.main_block.visible {
    opacity: 1; /* Блок становится видимым при прокрутке */
    transform:translateY(0);
}

.main_about_block {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 40px;
    align-items: center;
}

.main_h1 {
    width: 100%;
    height: 50px;
    font-family: "fontB", sans-serif;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.block2_container {
    margin-top: 30px;

    width: 100%;
    height: auto;

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: var(--main-color-3) !important;
}
.block2_container2 {
    margin-top: 30px;

    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    background-color: var(--main-color-3) !important;
}

.block2_container_first {
    width: 100%;
    height: 300px;
    max-width: 400px;

    background-image: url(../img/card.png);
    background-position: center;
    background-size: 400px;
    background-repeat: no-repeat;

    min-width: 350px;
}

.block2_container_sec {
    display: flex;

    flex-direction: column;
    padding: 40px 10px 50px 10px;
    color: white;
    width: 100%;

    max-width: 400px;

    min-width: 350px;
}

.block2_sec_button {

    margin-top: 15px;
    height: 50px;
    font-size: 20px;
}

.block2_sec_text_main {
    text-align: left;
    font-size: 38px;
    margin-bottom: 10px;
    font-family: "fontR", sans-serif;

}

.block2_sec_text_sec {

    font-size: 17px;
    color: #999;
    font-family: "fontR", sans-serif;

}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999999;
}

.paral {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 450px;

    position: absolute;
    z-index: 10000000000;
    overflow: hidden!important;
    display: flex;
    flex-direction: column;
}

.kinotel{
    background-image: url("../img/kinotel.png");
    background-position: center;

    width: 100%;
    position: relative;
    height: 100%;
    object-fit: cover;
    opacity: 40%;

}

.modal-title{
    width: 100%;
    height: auto;
    margin-top: 10px;
    color: #1a1919;
    font-size: 15px;
    font-family: "fontB", sans-serif;
}

.modal-description{
    width: 100%;
    height: auto;
    margin-top: 10px;
    font-size: 13px;
    color: #1a1919;
    font-family: "fontR", sans-serif;
}

.basket{
    width: 45px;
    border-radius: 10px;
    height: 45px;
    background-color: #efefef;
    float: right;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bonus_status{
    margin-top: 10px;

}


.basket svg{

    stroke: black;
    width: 25px;
    height: 25px;
}
.price_basket{
    align-items: center;

    display: flex;
    width: 100%;
    height: auto;
    margin-top: 10px;
    color: #1a1919;
    font-size: 30px;
    font-family: "fontB", sans-serif;
}
.close {
    font-family: "fontB", sans-serif;
    font-size: 35px;
    position: absolute;
    top: -10px;
    right: 5px;
    cursor: pointer;

}
.no-products{
    font-size: 20px;
    color: white;
    padding: 40px;
    font-family: "fontR", sans-serif;
}


.modal-image {
    max-width: 100%;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    max-height: 250px;

    margin: 0 auto;
}
.modal-image img{
  width: 100%;
    height: auto;
}
.footer_sec{
    width: 50%;
   display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;

    min-width: 350px;
    max-width: 1200px;
}

.footer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

iframe {
    /*border-radius: 20px;*/
    /*border-style: none;*/
    width: 100%;
    /*box-sizing: border-box;*/
    height: 100%;
    min-height: 85vh;
    border: none;

}

.map-container {
    width: 100%;
    /*max-width: 800px;*/
    height: 100%;
    margin: 20px auto;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}

.map-iframe {
    width: 100%;
    height: 450px;
    border: none;
}
