
.product-info h2 {
    margin: 8px 0;
}

.product-info p {
    margin: 4px 0;
}

.menu_items_container {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    margin-top: 20px;


    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}


.company_adv {


    display: flex;
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    height: 200px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
}

.company_adv_item {
    background-position: center;
    background-size: cover;
    position: relative;

    border-radius: 10px;
    height: 200px;
    min-width: 300px;
    margin-right: 10px;
    font-size: 20px;
    font-family: "fontA", sans-serif;
    font-weight: 700;
    padding: 10px 30px 10px 30px;

    color: white;

}

.loading_ico {
    color: white;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: "fontA", sans-serif;
    font-size: 25px;
    margin-top: 50px;
}
.company_image {
    margin: 0 auto;
    border-radius: 10px;
    width: 100%;
    max-width: 1230px;
    height: 375px;
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.company_image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0) 70%);
    pointer-events: none; /* чтобы не блокировать взаимодействие с элементом */
    border-radius: inherit; /* наследовать border-radius от родительского элемента */
}


/*.company_text_description{*/
/*    width: 100%;*/
/*    max-width: 400px;*/

/*    position: relative;*/

/*}*/
.company_description {
    max-width: 350px;
    top: 70px;
    left: 8px;
    position: absolute;
    font-family: "fontR", sans-serif;
    font-size: 14px;
    color: white;

}
.company_text {
    top: 10px;
    left: 8px;
    position: absolute;
    font-family: "fontA", sans-serif;
    font-size: 3.5em;
    color: white;

}

.company_text_description {
    width: 92%;
    height: 161px;
    max-width: 400px;
    position: absolute;
    top: 20px;
    left: 10px;
    background: rgb(7 7 7 / 18%);
    color: white;
    padding: 10px;
    border-radius: 8px;
    z-index: 10;

}
.company_slider {
    width: 100%;
    height: 375px;
}

.company_slide_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/*.company_slide_img::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    background: rgba(0, 0, 0, 0.8);*/
/*}*/


/*.nadimi {*/
/*    background-image: url(../img/nadimi.jpg);*/
/*}*/

/*.copacabana {*/
/*    background-image: url(../img/copacabana.jpg);*/
/*}*/

/*.aromiitaliani {*/
/*    background-image: url(../img/aromiitaliani.jpg);*/
/*}*/

/*.symphosium {*/
/*    background-image: url(../img/symphosium.jpg);*/
/*}*/

/*.poshnosh {*/
/*    background-image: url(../img/poshnosh.jpg);*/
/*}*/
/*.cave {*/
/*    background-image: url(../img/cave.jpg);*/
/*}*/

.Product_images {
    align-items: center;
    background-color: transparent!important;
    border-radius: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    height: 300px;
    max-width: 1000px;
    object-fit: cover;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: 100%;
}
.Product_images img {
    height: 500px;
    width: auto;
}



.company-text {
    bottom: 20px;
    position: absolute;
    right: 20px;
    font-size: 30px;
}

.menu_items_containerB{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;

    padding-bottom: 20px;


    flex-wrap: wrap;
}
.product-card {
    align-items: center;
    color: white;
    padding: 10px;
    display: flex;
    width: 100%;
    max-width: 380px;
    height: auto;

    position: relative;
    border-radius: 10px;
    background-color: var(--main-color-3);
    margin: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}




.loading{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: "fontB", sans-serif;
    font-size: 30px;
    color: white;
}


.read_more{
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-family: "fontB", sans-serif;
}

.button-group {
    max-width: 1240px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex;
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    height: 80px;
    padding: 10px;
}

.button-group::-webkit-scrollbar {
    height: 6px; /* Высота скроллбара */
}

.button-group::-webkit-scrollbar-thumb {
    background: #ffffff; /* Цвет ползунка */
    border-radius: 10px; /* Скругление углов */
}

.button-group::-webkit-scrollbar-track {
    background: #000000; /* Цвет фона скроллбара */
}
.button-groupa::-webkit-scrollbar {
    height: 6px; /* Высота скроллбара */
}

.button-groupa::-webkit-scrollbar-thumb {
    background: #ffffff; /* Цвет ползунка */
    border-radius: 10px; /* Скругление углов */
}

.button-groupa::-webkit-scrollbar-track {
    background: #000000; /* Цвет фона скроллбара */
}

.select-category-message{
    color: white;
}
.product-grid{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    margin-bottom: 10px;
    width: 100%;
}
.product-grid.active{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 50px;
    margin-bottom: 10px;
    width: 100%;
}

.arrow{
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    margin-left: auto;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222222;
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Устанавливаем прозрачность фона */

}

.product_grid_content{
    position: absolute;
    left: 0;
    z-index: 10000;
    overflow: hidden;
    width: 100%;
    padding: 10px;
}
.product_grid_category{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    height: 50px;

    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    padding: 10px;
    font-family: "fontR", sans-serif;

}
.basket_number{
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #f6b704;
    padding: 12px;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    font-family: "fontB", sans-serif;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
}
.dopFilterSearch{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;

    padding-right: 10px;
    padding-left: 10px;
}
.dopFilter{

    width: 100%;
    height: 50px;
    margin-top: 5px;
    padding-right: 12px;
    padding-left: 12px;
}
.dopFilter select{
    border-radius: 10px;
    margin: 0;
    height: 40px;
    width: auto;
    float: right;
    background-color: var(--main-color-3);
    color: white;
    font-size: 17px;
}
.search-input{
    outline: none !important;
    width: 100%;
    padding: 10px;
    height: 40px;
    border-radius: 10px;
    background-color: #242424;
    color: #ffffff;
    border-style: none;
    font-size: 23px;
    margin-top: 14px;
}
.close{
    font-size: 20px;
    font-family: "fontR", sans-serif;
    height: 35px;
    margin-top: 39px;
    left: 15px;
    width: 100px;
    display: flex;
    color: #000000 !important;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: white;
}

.IconsB{

    bottom: 80px;
    border-radius: 10px;
    width: 70px;
    height: 70px;
    float: right;
    position: fixed;
    z-index: 1000;
    background-color: white;
    margin-left: 10px;
    overflow-y: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.IconsB svg{
    stroke: black;
    width: 45px;
    height: 45px;
}

.product-price {
    display: flex;
    float: right;
    padding: 5px;
   width: 100%;
    margin-left: auto;


    align-items: center;
    justify-content: center;
    text-align: center;

    font-family: "fontB", sans-serif;
    font-size: 25px;

}

.price_lari {
    font-size: 15px;
    margin-left: 5px;
}

.product_price_text {
    display: flex;
    float: left;

    align-items: center;


}

.menu_item_card_img {
    background-color: #171717 !important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 10px;
    overflow: hidden;
    width: 120px !important;

    height: 115px !important;
    object-fit: cover;
}

.product-image {
    height: 100% !important;
    width: auto;
    object-fit: cover;

    border-radius: 4px;
}

.product-info {
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding: 5px;
    height: auto;
    width: 100%;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;


}

.product_description {
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: auto;

    font-size: 12px;
    font-family: fontI, sans-serif;
}

.product_title {
    padding: 5px;
    text-align: left;
    width: 100%;
    text-transform: uppercase;
    font-family: "fontR", sans-serif;
    font-size: 15px;
}

.total-price{
    width: 100%;
    height: auto;
    color: white;
    align-items: center;
    font-family: "fontR", sans-serif;
    font-size: 20px;
    display: flex;
    padding:0 20px 20px 20px;
}

.price_num{
    font-family: "fontB", sans-serif;
    font-size: 30px;
    margin-left: 10px;
}



.quantity-controls span{
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.quantity-controls{
    padding-right: 7px;
    width: 100%;
    margin: 15px;
    font-family: "fontB", sans-serif;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 40px;
}

.modal .close{
    width: 40px!important;
    font-size: 30px!important;
    color: #1a1919;
    font-weight: 700!important;
    height: 40px!important;
}

.quantity-controls button{
    width: 50px;
    border-radius: 10px;
    height: 50px;
}
.button-groupa {
    max-width: 1240px;
    margin: 0 auto;

    display: flex;
    width: 100%;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    height: 90px;
    padding: 10px;
}
.filter-button.active{

    color:  #1a1919 !important;
    background-color: white !important;

}
.filter-button {
    color: white !important;
    background-color: #1a1919 !important;
    position: relative;
    height: 50px;
    margin-right: 10px;
    font-size: 20px;
    padding: 0 20px; /* Добавление отступов внутри кнопки */
    width: auto; /* Или fit-content */
    box-sizing: border-box;
    text-align: center;
    font-family: "fontA", sans-serif;
    font-weight: 700;
    z-index: 1000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    white-space: nowrap;
}

.company-button.active {

    color:  #1a1919 !important;
    background-color: white !important;
}
.company-button {
    color: white !important;
    background-color: #1a1919 !important;
    height: 55px;
    margin-right: 10px;
    font-size: 20px;
    font-family: "fontA", sans-serif;
    font-weight: 700;
    padding: 10px 30px 10px 30px;
}


.GeorgianKitchen {

}

.Beverages {
    /* Стили для Beverages */
}

.SandiwchesWraps {
    /* Стили для SandiwchesWraps */
}

.TexMex {
    /* Стили для TexMex */
}

.BossBurgers {
    /* Стили для BossBurgers */
}

.Sides {
    /* Стили для Sides */
}

.Sauces\Dips {
    /* Стили для Sauces&Dips */
}

.Drinks {
    /* Стили для Drinks */
}

.Shawarma {
    /* Стили для Shawarma */
}

.Chickenshawrma {
    /* Стили для Chickenshawrma */
}

.Softdrinks {
    /* Стили для Softdrinks */
}

.Bakery {
    /* Стили для Bakery */
}

.Cozinha {
    /* Стили для Cozinha */
}

.SushiBar {
    /* Стили для SushiBar */
}

.Bar {
    /* Стили для Bar */
}

.Breakfast {
    /* Стили для Breakfast */
}

.Caffetteria {
    /* Стили для Caffetteria */
}

.Trattoria {
    /* Стили для Trattoria */
}

.Pizzeria {
    /* Стили для Pizzeria */
}

.Cantina {
    /* Стили для Cantina */
}

.Boards {
    /* Стили для Boards */
}

.Georgianwine {
    /* Стили для Georgianwine */
}

.Foreignwine {
    /* Стили для Foreignwine */
}

.Brandy\&Chacha {
    /* Стили для Brandy&Chacha */
}

.Nonalcoholicdrinks {
    /* Стили для Nonalcoholicdrinks */
}

.button-group::-webkit-scrollbar {
    height: 6px; /* Высота скроллбара */
}

.button-group::-webkit-scrollbar-thumb {
    background: #ffffff; /* Цвет ползунка */
    border-radius: 10px; /* Скругление углов */
}

.button-group::-webkit-scrollbar-track {
    background: #000000; /* Цвет фона скроллбара */
}
.button-groupa::-webkit-scrollbar {
    height: 6px; /* Высота скроллбара */
}

.button-groupa::-webkit-scrollbar-thumb {
    background: #ffffff; /* Цвет ползунка */
    border-radius: 10px; /* Скругление углов */
}

.button-groupa::-webkit-scrollbar-track {
    background: #000000; /* Цвет фона скроллбара */
}
















