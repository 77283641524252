.loyalty-crm-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.back-button {

    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background 0.3s ease;
}

.back-button:hover {

}

h1 {
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

.loyalty-crm-container input[type="text"] {
    width: 100%;
    padding: 12px 20px;
    margin: 20px 0;
    border: 2px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
}

input[type="text"]:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}
.loyal-card{
    background: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #eee;
}
.card-item {

}

.card-item h3 {
    color: #2c3e50;
    margin: 0 0 10px 0;
    font-size: 1.2em;
}

.card-item p {
    color: #7f8c8d;
    margin: 5px 0;
    font-size: 0.9em;
}

.user-info {
    padding: 15px;
    background: #f8f9fa;
    border-radius: 8px;
    margin: 15px 0;
}

.user-info h4 {
    color: #34495e;
    margin: 0 0 10px 0;
    font-size: 1em;
}

.coins-info {
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #eee;
}

.coins-info h4 {
    color: #34495e;
    margin: 0 0 15px 0;
    font-size: 1em;
}

.coins-info div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 8px;
    background: #f8f9fa;
    border-radius: 6px;
}
.store-name{
    margin-right: 10px;
}
.edit-controls{
    display: flex;
    gap: 10px;
}
.Bascket-price {
    color: white;
}
.edit-controls input{
    border-radius: 10px;
    border:1px solid black;
    padding: 10px;
}
.coins-info button {
    background: #007bff;
    color: white;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s ease;
}

.coins-info button:hover {
    background: #0056b3;
}

.user-missing {
    color: #e74c3c;
    padding: 10px;
    background: #f8d7da;
    border-radius: 6px;
    margin: 15px 0;
}

@media (max-width: 768px) {
    .cards-list {
        grid-template-columns: 1fr;
    }

    .loyalty-crm-container {
        padding: 15px;
    }
}