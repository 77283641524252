.Buy{
    width: 100%;
    height: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.Buy_conatiner{
    width: 100%;
    height: 100%;

}

.Buy_item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-family: "fontB", sans-serif;
}
.Buy_item input{
    width: 70%;
    height: 30px;
    font-family: "fontB", sans-serif;
    font-size: 20px;
}