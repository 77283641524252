/* Общие стили */
.LoyaltyPage-container {
    padding-top: 65px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
    box-sizing: border-box;
    margin-bottom: 120px;
    background-color: #000; /* Чёрный фон */
    color: #fff; /* Белый текст */
    font-family: 'Arial', sans-serif;
}
.Benefits-main-text{
    color: var(--main-color-1);
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
}
/* Заголовок */
.main_blocks_text {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 7px;
    letter-spacing: 2px;
}
.loyalpage-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
}

.loyalpage-welcome {
    text-align: center;
    margin-bottom: 2.5rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 12px;
}

.loyalpage-title {
    color: #2c3e50;
    font-size: 1.8rem;
    margin-bottom: 1rem;
}

.loyalpage-lead {
    color: #7f8c8d;
    font-size: 1.1rem;
}

.loyalpage-section {
    position: relative;
    margin: 2rem 0;
    padding: 1.5rem;
    border-left: 4px solid #3498db;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.loyalpage-bonus {
    border-color: #e67e22;
}

.loyalpage-icon {
    position: absolute;
    left: -1.2rem;
    top: -1.2rem;
    background: white;
    border-radius: 50%;
    font-size: 1.8rem;
    padding: 0.5rem;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.loyalpage-subtitle {
    color: #34495e;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.loyalpage-text {
    color: #555;
    margin: 0.8rem 0;
}

.loyalpage-list {
    list-style: none;
    padding-left: 1.5rem;
    margin: 1.2rem 0;
}

.loyalpage-list-item {
    position: relative;

    margin: 0.8rem 0;
    color: black;
}


.loyalpage-highlight {
    color: #27ae60;
    font-weight: 600;

    background: #e8f6ef;
    border-radius: 4px;
}

.loyalpage-formula {
    padding: 1.2rem;
    margin: 1rem 0;
    background: #fff4e6;
    border-radius: 8px;
    border: 1px dashed #e67e22;
}

.loyalpage-note {
    margin-top: 1rem;
    font-style: italic;
    color: #e67e22;
    font-size: 0.95rem;
}

@media (max-width: 768px) {
    .loyalpage-container {
        padding: 1rem;
    }

    .loyalpage-title {
        font-size: 1.5rem;
    }

    .loyalpage-section {
        margin: 1.5rem 0;
    }
}
/* Контейнер преимуществ */
.benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.benefits-item {
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    background-color: #1a1a1a; /* Тёмно-серый фон */
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.benefits-item:hover {

    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.2);
}

.benefits-title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.benefits-text {
    font-size: 1rem;
    line-height: 1.6;
    word-wrap: break-word;
}

/* Кнопки */
.loyalty-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


/* Выбор карты */
.choose-card-container {
    text-align: center;
}

.choose-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.silver-card-button {
    background-color: #C0C0C0;
    color: black;
    margin-right: 10px;
}

.gold-card-button {
    background-color: #FFD700;
    color: black;
}

.silver-card-button:hover, .gold-card-button:hover {
    filter: brightness(1.2);
}

/* Ввод кода карты */
.enter-card-code-container {
    text-align: center;
}

.enter-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.card-code-input {
    width: 80%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid white;
    background: transparent;
    color: white;
    text-align: center;
    margin-bottom: 15px;
}

.submit-card-button {
    width: 80%;
    background-color: white;
    color: black;
    font-weight: bold;
}

.submit-card-button:hover {
    background-color: #ccc;
}

/* Кнопка назад */
.back-button {
    display: block;
    margin: 20px auto;
    width: 80px;
    text-align: center;
}
.error-loyalty-message{
    text-align: center;
    margin-top: 20px;
    color: yellow;
    cursor: pointer;
    text-decoration: underline;
}

.loyal-summary {


    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}
.user-card-wrapper{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.user-card-info{
    max-width: 390px;

}
.loyality1{
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 400px;

}
.become-beneficcer{
    scale: 1.5;
    margin-bottom: 10px;

    border:1px solid white;
}
.loyal-progress {

    background: linear-gradient(90deg, #ffcc00, #ff9900);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.5s ease-in-out;
}

.loyal-value {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
}

.loyal-currency {
    font-size: 12px;
    font-weight: normal;
    opacity: 0.8;
}

.bonus-info {
    font-size: 14px;
    font-weight: bold;
    color: #555;
    margin-top: 10px;
}

.bonus-summary {


    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
}

.bonus-progress {

    background: linear-gradient(90deg, #66ccff, #3399ff);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 0.5s ease-in-out;
}

.bonus-value {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
}

.bonus-currency {
    font-size: 12px;
    font-weight: normal;
    opacity: 0.8;
}