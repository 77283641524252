
.chatbox_msg_container {
    position: relative;
    overflow: hidden;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding-bottom: 120px;
}


.user_count {
    width: 30px;
    height: 30px;
    position: absolute;
    color: white;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: 50%;
    background-color: #0080FF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "fontB", sans-serif;
    font-size: 14px;
    z-index: 10000;
}

.user_count:empty {
    display: none;
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    border-radius: 10px;
    max-width: 400px;
    height: 500px;
    position: relative;
    overflow: hidden;
    text-align: center;
}


.ex_btn{
    width: 30px;
    height: 30px;
    background-color: var(--main-color-3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    top: 30px;
    right: 30px;
    position: absolute;
}
.ex_btn svg{
    stroke: var(--main-color-1);
}


.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.user-message.right {
    float: right;
    margin-left: auto;
}

.user-message {
    width: auto;
    height: auto;
    display: flex;

    margin: 5px;
}

.user-message .msg_container {
    background-color: var(--main-color-3);
    color: var(--main-color-1);
}

.user-message.right .msg_container {
    background-color: var(--main-color-6);
    color: white;
}

.user-message .chat_user_img {
    margin-right: 10px;
}

.user-message.right .chat_user_img {
    margin-left: 10px;
}

.user-message.right .chat_user_img {
    float: right;

}

.chat_user_img {

    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: var(--main-color-3);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}


.msg_container {
    width: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    max-width: 500px;
    align-items: flex-end;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
}

.msg {
    flex-grow: 1;

    word-wrap: break-word;
}


.orders_list_item_time {
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-family: "fontB", sans-serif;
    font-size: 14px;
    color: #777777;
}

.message_right {
    position: relative;

    float: right;
    margin-left: auto;
    width: auto;
    height: auto;
    max-width: 300px;
    border-radius: 10px;
    background-color: #0080FF;
    color: white;
    padding: 10px 25px 20px 10px;
    margin-top: 5px;
    font-family: "fontS", sans-serif;
}
.order_img{
    height: 170px;
    margin-bottom: 20px;
}

.no_orders {
    text-align: center;
    width: 100%;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: "fontB", sans-serif;
    height: 100%;
}
.no_orders h2{
    font-size: 22px;
}
.no_orders h3{
    font-size: 15px;
    font-family: fontR,sans-serif;
}

.absolute_chat_img{
    flex-direction: column;
    position: absolute;
   display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    overflow: hidden;
}
.absolute_chat_img img{
    width: 200px;
}

.absolute_chat_text{
    margin-top: 20px;
    text-align: center;
    font-family: "fontB", sans-serif;
    font-size: 17px;
}

.unread_count{
    position: absolute;
    top: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #f74a78;
    color: white;
    font-family: "fontB", sans-serif;
    font-size: 14px;
    right: 10px;
}

.no_orders button {
    overflow: hidden;
    margin-top: 20px;
    padding: 10px;
    background-color: var(--main-color-6) !important;
    border-radius: 10px;
    border-style: none;
    bottom: 0;
    color: white;
    cursor: pointer;
    font-family: fontB, sans-serif;
    height: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
}

.message_left {

    position: relative;
    float: left;
    width: auto;
    height: auto;
    max-width: 300px;
    border-radius: 10px;
    background-color: var(--main-color-2);
    color: var(--main-color-1);
    padding: 10px 10px 20px 10px;
    margin-top: 5px;
    font-family: "fontS", sans-serif;
}

.chat_time {
    position: absolute;
    right: 5px;
    font-size: 10px;
    bottom: 3px;
    opacity: 50%;

}

.chat_box {
    width: 100%;
    height: calc(100vh - 330px);
    border-radius: 10px;
    margin-top: 5px;
    background-color: var(--main-color-3);
    padding: 10px;
    position: relative;

    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

.chatbox {
    width: 100%;
    height: 100%;
    display: flex;
    padding-bottom: 60px;
    flex-direction: column;
}


.msg {
    width: 100%;
    height: 100%;
    font-family: fontR, sans-serif;
    font-size: 15px;

}

.chatbox_xbar {
    background-color: #01d279;
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
}

.chatbox_xbar_items {

    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    background-color: var(--main-color-3) !important;
    height: 100%;
    border-radius: 10px;
}

.chat_box_msg {
    outline: none;
    font-size: 15px;
    width: 100% !important;
    height: auto;
    min-height: 50px;
    font-family: "fontR", sans-serif;
    border-radius: 10px;
    color: var(--main-color-1) !important;
    padding: 10px;
    border-style: none !important;
    background-color: var(--main-color-3) !important;
}


.ChatBox_users {
    width: 100%;
    margin-bottom: 20px;
    height: 50px;
    display: flex;
    align-items: center;

}

.chatbox_loading_wa {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatbox_loading_wa img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

.ChatBox_user_icon {
    background-color: #eaeaea;

    width: 45px;
    border-radius: 50%;
    height: 45px;
    position: relative;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    font-family: fontB, sans-serif;

}

.Chatbox_user_name {
    font-size: 10px;
    position: absolute;
    bottom: -20px;
    transform: translate(-50%, -50%);
    left: 50%;
}


.horizontal-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.horizontal-list li {
    margin-right: 10px;
    cursor: pointer;
}


.horizontal-list li:last-child {
    margin-right: 0;
}

.msg_send_form input {
    max-width: 390px;
    width: 100%;
    outline: none;
    font-size: 15px;

    height: auto;
    min-height: 50px;
    font-family: "fontR", sans-serif;
    border-radius: 10px;
    color: var(--main-color-1) !important;
    padding: 10px;
    border-style: none !important;
    background-color: var(--main-color-3) !important;
}

.msg_send_form {
    width: 100%;
    position: fixed;
    max-width: 390px;
    z-index: 10000;
    bottom: 55px;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    margin-top: 10px;
    display: flex;
    padding: 10px 10px 10px 10px;
    background-color: var(--main-color-3) !important;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
}

.ChatBox_searchline {
    background-color: var(--main-color-3) !important;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;

}

.ChatBox_searchline_button {
    width: 47px;

    border-radius: 10px;
    border-style: none;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;

}


.ChatBox_searchline_button svg {
    stroke: var(--main-color-1) !important;
}

.chat_check {
    position: absolute;
    right: 33px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 2px;
}

.chat_check svg {
    width: 15px;
    height: 15px;
}

.ChatBox_searchline input {
    width: 100%;
    outline: none;
    font-size: 15px;
    width: 100% !important;
    height: auto;
    min-height: 50px;
    font-family: "fontR", sans-serif;
    border-radius: 10px;
    color: var(--main-color-1) !important;
    padding: 10px;
    border-style: none !important;
    background-color: var(--main-color-3) !important;
}


.chat-container_admins_form {
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container_admins_form_container {
    display: flex;
    position: relative;
    overflow: hidden;
    max-width: 400px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.chat_container_admins_form_item {
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-family: "fontB", sans-serif;
    background-color: var(--main-color-2) !important;
    color: var(--main-color-1) !important;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat_container_admins_form_item.active {
    padding: 10px;
    border-radius: 10px;
    font-size: 16px;
    font-family: "fontB", sans-serif;
    background-color: var(--main-color-6) !important;
    color: white !important;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}


.chat_container_admins_form_item svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
}

.orders_list {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}

.orders_list_container {
    max-width: 400px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    flex-direction: column;
}


.orders_list_item {
    border-radius: 10px;
    padding: 30px 10px 10px 10px;
    font-family: "fontB", sans-serif;
    width: 100%;
    height: auto;
    cursor: pointer;
    display: flex;
    position: relative;
    overflow: hidden;
    background-color: var(--main-color-3) !important;

    margin-top: 10px;

    align-items: center;

}

.orders_list_item_id {

    font-family: "fontR", sans-serif;
    color: white!important;
    font-size: 15px;
    margin-top: 10px;
}

.chat-messages {
    width: 100%;
    max-width: 400px;
    justify-content: center;
    align-items: center;
    margin: 80px auto;

}


.order_type {
    width: 100%;
    height: 100%;

    display: flex;

    align-items: center;
}

.order_type_content {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    background-color: var(--main-color-6) !important;
    color: white;
    max-width: 400px;
    justify-content: center;
    align-items: center;
}

.order_type_item {
    margin: 5px;
    width: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "fontB", sans-serif;
}














