* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;

}
iframe {
  width: 100%;
  height: 500px;
  border: none;
}
.place-card {
  position: absolute;
  top: 50%;
  left: 50%;
}
.subcategory-tabs {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;

  border-radius: 10px;
  scrollbar-width: thin;
  scrollbar-color: #999 transparent;
}

/* Стили для скрытия стандартного скроллбара */
.subcategory-tabs::-webkit-scrollbar {
  height: 5px;
}

.subcategory-tabs::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

.subcategory-button {
  flex: 0 0 auto;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subcategory-button:hover {
  background: #f0f0f0;
}

.subcategory-button.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(255, 102, 0, 0.3);
}


html, body {
  background-color: var(--main-color-2);
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: clip;
  -webkit-overflow-scrolling: touch;

  /*scroll-behavior: smooth;*/
}


.main{
  object-fit: cover;
  overflow-x: hidden;

  color: var(--main-color-1);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;


}
#recaptcha-container{
  position: absolute;
  top:50%;
  left: 50%;
}
.subcategory-header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 5px;
  border:1px solid var(--main-color-2);
  border-radius: 10px;
}

.edit-category-btn{
  margin-top: 0;
  margin-left: 10px;

}
.global_main{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.global_input{
  width: 100%;
  height: 50px;
}
.registration-modal{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.auth-checkbox-container{
  display: flex;
  flex-direction: column;
  color: white;
}
.auth-checkbox-container label{
  display: flex;
  align-items: center;

  gap: 10px;
}
.registration-modal input{
  background-color: var(--main-color-3) !important;
  /* border: none; */
  /* border-radius: 15px; */
  box-sizing: border-box;
  color: var(--main-color-1) !important;
  cursor: pointer;
  font-family: fontB, sans-serif;
  font-size: 18px;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
  outline: none;
  padding: 0 8px;
  max-width: 340px;
  border: 1px solid #777777;
  border-radius: 4px;
  transition: box-shadow 0.9s ease
}
.registration-modal p{
  color: white;
  width: 340px;
}
.comingsoon{
  width: 100%;
  height: 100%;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "fontR", sans-serif;
  font-size: 30px;
  color: white;
}
.add-delivery-container{
  display: flex;
  flex-direction: column;

  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.last-delivery-block{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.delivery-input{
  background-color: var(--main-color-2);
  color: white;
  margin:5px 0;
  padding: 10px;
  border: 1px solid #ebebeb;;
  border-radius: 10px;
  width: 100%;

}
.delivery-crm *{
  color: var(--main-color-1);
}
.deliveryproduct{
  border: 1px solid white;
}
.change-input{
  background-color: var(--main-color-2);
  border-radius: 10px;
  border: 1px white solid;
  color: #afafaf;
  padding: 10px;
  margin-top: 10px;
}
.datetime-input{
  background-color: var(--main-color-2);
  border-radius: 10px;
  border: 1px white solid;
  color: #afafaf;
  padding: 10px;
}
.map-container {
  width: 100%;
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.ordercrm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.ordercrm-container * {
  color: white;
}
.ordercrm-table {
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
  font-size: 14px;
  overflow-x:auto ;
}

.ordercrm-table th, .ordercrm-table td {
  padding: 8px;
  border: 1px solid #ccc;
  text-align: left;
}

.ordercrm-table th {
  font-weight: bold;
  white-space: nowrap;
}

.ordercrm-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.ordercrm-select {
  font-size: 14px;
  padding: 4px;
  border: 1px solid #ccc;
  background: none;
}
.ordercrm-product {
  white-space: normal;
  word-wrap: break-word;
  padding: 10px ;
}
.ordercrm-button {
  font-size: 14px;
  padding: 4px 8px;
  border: none;
  cursor: pointer;
}

.ordercrm-loading {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}



.map {
  width: 100%;
  height: 100%;
}
.address-input-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.input-group {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;

}
.bascket-container{
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}
.Sposob-dostavki{
  color: white;
}
.DeliveryWay-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.basket-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.input-group label {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 4px;
  padding-left: 5px;
}

.delivery-container{
  width: 100%;

  display: flex;
  gap: 10px;
  justify-content: center;
  padding-top: 60px;
  color: white;
}

.delivery-time-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}

.switch-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 60px;
  min-height: 30px;
}

.switch-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 26px;
  background-color: #ccc;
  border-radius: 34px;
  transition: .4s;
}

.switch-slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}

.switch-input:checked + .switch-slider {
  background-color: #606060;
}

.switch-input:checked + .switch-slider:before {
  transform: translateX(24px);
}

.switch-text {
  margin-left: 15px;
  font-size: 16px;
  color: #cdcdcd;;
}

/* Для анимации при наведении */
.switch-option:hover .switch-slider {
  background-color: #b3b3b3;
}

.switch-option:hover .switch-input:checked + .switch-slider {
  background-color: #707070;
}
.counter-value{
  color: white;
}
.counter-control{
  display: flex;
  gap: 10px;
  align-items: center;
}
:root {
  /*--main-color-0: #131312;*/
  /*--main-color-1: #ffffff;*/
  /*--main-color-2: #131312;*/
  /*--main-color-3: #1a1a1a;*/
  /*--main-color-6: #1a1919; */

  --main-color-0: #000;
  --main-color-1: #ffffff;
  --main-color-2: #000;
  --main-color-3: #000;
  --main-color-6: #000;
}
@font-face {
  font-family: 'fontA';
  src: local('fontA'), url(../fonts/A.ttf) format('truetype');
}
@font-face {
  font-family: 'fontR';
  src: local('fontR'), url(../fonts/R.ttf) format('truetype');
}
@font-face {
  font-family: 'fontS';
  src: local('fontS'), url(../fonts/S.ttf) format('truetype');
}
@font-face {
  font-family: 'fontM';
  src: local('fontM'), url(../fonts/M.ttf) format('truetype');
}
@font-face {
  font-family: 'fontB';
  src: local('fontB'), url(../fonts/B.ttf) format('truetype');
}
@font-face {
  font-family: 'fontI';
  src: local('fontI'), url(../fonts/I.ttf) format('truetype');
}