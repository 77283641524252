
.auth {
    padding: 60px 10px 120px 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    flex-direction: column;
    align-items: center; /* Центрирование по горизонтали */
    justify-content: center;
    display: flex;
}


.status2{
    background-color: #f6b704!important;

}


.products_conatiner_back_button {
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 2px;
    font-size: 15px;
    font-family: "fontB", sans-serif;
    background-color: var(--main-color-3) !important;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.auth_container {

    object-fit: cover;
    overflow: hidden;
    margin-top:0px;

    padding: 10px;
    margin: auto;
    max-width: 385px !important;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.auth_container h1 {
    margin-top: 20px;
    color: white;
    text-align: left;
    font-size: 30px;
    font-family: "Roboto", sans-serif;
    overflow-wrap: break-word;
    margin-left:0px;
}
.bonus_status{
    width: auto;
    height: auto;
    color: white;
    font-size: 19px;
    padding: 5px;
    font-family: "fontB", sans-serif;
;
}
.User_ID{
    text-align: left;
    font-size: 15px;
    font-family: "fontB", sans-serif;
    color: #777777;
}
.text-recaptcha {
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}
.login_error-message{
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-family: "fontB", sans-serif;
    color: #ff2626;
}
.auth_container h2 {

    margin-top:20px;
    text-align: left;
    font-size: 16px;
    color: white;
    font-family: Arial, sans-serif;
    font-weight: 300;
}

.auth_container h3 {
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
    font-family: "fontR", sans-serif;
    color: var(--main-color-1) !important;
}

.auth_container_button {
    background-color: var(--main-color-1) !important;
    color: var(--main-color-2) !important;
    width: 100%;
    max-width: 350px;
    height: 60px;
    margin-top: 10px;
    font-family: "fontB", sans-serif;
    font-size: 15px;
    border-style: none;
    border-radius: 17px;
}

.auth_container_input {

    background-color: var(--main-color-3) !important;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
    color: var(--main-color-1) !important;
    cursor: pointer;
    font-family: fontB, sans-serif;
    font-size: 18px;
    height: 55px;
    margin-bottom: 10px;
    margin-top: 10px;
    outline: none;
    padding: 0 20px;
    width: 100%;
    max-width: 350px;
}


#Password {
    margin-bottom: 10px !important;
}

.passcont {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    position: relative;
    align-self: center;
    margin: 0 auto;
    width: 100%;

}


.pasicon {
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: center;
    height: 40px;
    width: 40px;
    position: absolute;
    border-radius: 10px;
    right: 0;
}

.pasicon svg {
    stroke: var(--main-color-1);
}

.pasicon.active svg {
    stroke: var(--main-color-2);

}

.pasicon.active {
    background-color: var(--main-color-1);
}


.User {
    max-width: 500px !important;
    margin: 25px auto;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.User_container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.User_img {
    border-radius: 50px;
    display: flex;
    align-items: center;

    box-sizing: border-box;
    overflow: hidden;
    justify-content: center;
    width: 100px !important;
    height: 100px !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--main-color-1) !important;
}

.auth_container_2{
    font-family: fontS,sans-serif;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: flex;
    max-width: 360px;
    text-align: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}
.auth_container_2 h3{
    width: 100%;
    text-align: left;
    margin-left: 15px;
    font-family: "fontS",sans-serif;
}
.User_text {
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;
    font-family: "fontB", sans-serif;
    font-size: 24px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.hidden-input {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.User_phone {
    font-size: 15px;
    margin-top: 15px;
}

.user_menu {
    width: 100%;
    height: 100%;
    margin-top: 40px;

}

.user_menu_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.user_menu_item {
    color: white;

    cursor: pointer;
    align-items: center;

    height: 50px;

    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    font-family: "fontR", sans-serif;
    border-radius: 10px;
    width: 100%;

}

.dop_user_img svg {
    margin-right: 5px;
    width: 24px;
    height: 24px;
}

.dop_user_img {
    cursor: pointer;
    display: flex;
    align-items: center;

    width: 100%;
    height: 20px;
    font-size: 13px;
    font-family: fontS, sans-serif;
    margin-top: 10px;
}
.User_status_button{
    overflow: hidden;
    margin-top: 20px;
    padding: 10px;

    background-color: var(--main-color-3) !important;
    border-radius: 10px;
    border-style: none;
    bottom: 0;
    color: var(--main-color-1) !important;
    cursor: pointer;
    font-family: fontB, sans-serif;
    height: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    z-index: 1600;
}

.User_status_button svg{
    margin-right: 5px;
    stroke: var(--main-color-1) !important;
}
.modal_content_swither{
    font-family: "fontB", sans-serif;
    font-size: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 300px;


}

.modal_content_swither label{
    display: flex;
    width: 100%;
    font-size: 13px;
    justify-content: center;
    align-items: center;

}

.modal-content_inputs{
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}
.modal{
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content_inputs input{
    margin-top: 5px!important;
    font-family: "fontB", sans-serif;
}
.modal-content button{
    overflow: hidden;
    margin-top: 20px;
    background-color: var(--main-color-6) !important;
    border-radius: 10px;
    border-style: none;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-family: fontB, sans-serif;
    height: 60px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 300px;
    z-index: 1600;
}

.partner_status_svg{

    position: absolute;
    bottom: -10px;
    left: 0;
    background-color: #0080FF;
    display: flex;
    color: white;
    margin-top: -10px;
    z-index: 1000000;
    overflow: hidden;
    align-items: center;
    font-family: "fontB", sans-serif;
    width: 100px;
    border-radius: 10px;
    padding: 5px;
}

.cash{
    display: flex;
    font-family: "fontB", sans-serif;
    font-size: 35px;
    align-items: center;
    width: auto;
    border-radius: 10px;
    color: var(--main-color-1) !important;
    background-color:  var(--main-color-3) !important;
}



.modal_content_swither input{
    margin-right: 10px!important;
    width: 30px!important;
    border-radius: 20px!important;
}

.modal-content{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    margin-top: 15px;
}
.cls-3 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 2px;
    stroke: white;
}

.out_item svg {
    stroke: var(--main-color-1) !important;
    background-color:  var(--main-color-3) !important;
}

.user_menu_item svg {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--main-color-3) !important;
    padding: 7px;
    border-radius: 10px;
    stroke: white;
}

.mapboxgl-ctrl-geocoder--input {
    color: var(--main-color-1) !important;

}
.product_page_m{
    background-color:  var(--main-color-2) !important;
}


.user_add_items_container{
    display: flex;
    width: 100%;

    height: 70px;
}



.number_i{
    background-color: var(--main-color-3) !important;
    /*border: none;*/
    /*border-radius: 15px;*/
    box-sizing: border-box;
    color: var(--main-color-1) !important;
    cursor: pointer;
    font-family: fontB,sans-serif;
    font-size: 18px;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
    outline: none;
    padding: 0 8px;
    width: 340px;
    border: 1px solid #777777;
    border-radius: 4px;
    transition: box-shadow 0.9s ease;
}
.number_i:hover {
    box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.2); /* Белое свечение при hover */
}

.number_i:active {
    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.2); /* Белое свечение при нажатии */
}
.login-form-content-image {
    display: flex;
    justify-content: center; /* Центрирование изображения по горизонтали */
    align-items: center; /* Центрирование по вертикали (если потребуется) */
    width: 100%; /* Ширина блока */
    height: auto; /* Автоматическая высота */
    margin-bottom: 20px; /* Отступ от нижней части блока */
}

.login-form-image {
    background-image: url('../img/cave.jpg'); /* Ссылка на ваше изображение */
    background-size: cover; /* Масштабирование изображения */
    background-position: center; /* Центрирование изображения */
    width: 100%; /* Ширина блока */
    max-width: 340px; /* Максимальная ширина изображения */
    height: 200px; /* Высота изображения */
    border-radius: 10px; /* Скругленные углы, если нужно */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 15px rgba(255, 255, 255, 0.2); /* Добавлено белое свечение */
}

/**/
.login-form-body h1{
    color: white;
    font-size: 18px;
    margin-left: 10px;
}

.Registration_button{
    color: white;
    margin-bottom: 27px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 350px !important;
    margin-top:20px;
}
.reg_error_msg{
    font-family: "fontB", sans-serif;
    color: red;
    margin-top: 20px;
    margin-bottom: 20px;
}
.Registration_button svg{
    margin-left: 10px;
    margin-right: 10px;
    height: auto;
    width: auto;
}

.Registraton_button_sec{
    width: auto;
    cursor: pointer;
    font-family: "fontB", sans-serif;
    font-size: 15px;
}
.Registraton_button_first{
    width: auto;
    height: auto;
    object-fit: cover;
    font-family: "fontR", sans-serif;
    font-size: 15px;
    margin-bottom: 0px;
}










.user_add_item{
    width: 55px;
    height: 55px;
    background-color: #0080FF;
    margin: 5px;
    flex-wrap: wrap;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.user_add_item svg{
    stroke: white;
    width: 30px;
    height: 30px;
}
.addModal_item {
    width: 100%;
    height: 100%;
    position: relative;

}
.addModal_item back{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.addModal_text{
    width: 100%;
    font-family: "fontB", sans-serif;
}

.addModal{
    width: 100%;
    height: 100%;
    padding: 10px;


    background-color: var(--main-color-2) !important;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 1000000000000000000;
}


.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
    outline: none !important;
    border: none;
    box-shadow: none !important;
    background-color: var(--main-color-2) !important;

    font-family: "fontS", sans-serif !important;
    font-size: 15px;

}
.modal-content .register_b {
    width: 100%;
    font-family: "fontA", sans-serif;
    font-size: 25px;
    text-transform: lowercase;
}
.modal-content .login {
    width: 100%;
    font-family: "fontA", sans-serif;
    font-size: 15px;
    text-transform: lowercase;
}
.register_b{
    overflow: hidden;
    margin-top: 20px;
    background-color: var(--main-color-6) !important;
    border-radius: 10px;
    border-style: none;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-family: fontR, sans-serif;
    height: 60px;
    letter-spacing: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1600;
}
.User_name{
    color: white;
    font-family: "fontA", sans-serif;
}
.google-login-btn{
    overflow: hidden;

    width: 100%;
    background-color: var(--main-color-2) !important;
    border-radius: 10px;
    border-style: none;
    bottom: 0;
    color: #fff;
    cursor: pointer;
    font-family: fontR, sans-serif;
    height: 60px;
    letter-spacing: 1px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1600;
    transition: box-shadow 0.3s ease;
}
.google-login-btn2:hover {
    box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.3); /* Белое свечение при hover */
}

.google-login-btn2:active {
    box-shadow: 0 0 10px 3px rgba(255, 255, 255, 0.5); /* Белое свечение при нажатии */
    background-color: black; /* Черный фон при нажатии */
    color: #fff; /* Белый цвет текста при нажатии */
}


.google-login-btn2{
    overflow: hidden;
    /*background-color: var(--main-color-1) !important;*/
    background-color: #fffc !important;
    border-radius: 5px;
    border-style: none;
    bottom: 0;
    color: var(--main-color-2) !important;
    cursor: pointer;
    height: 44px;
    letter-spacing: 1px;
    font-size: 23px;
    font-family: "fontA", sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1600;
    margin-top:30px;
}

.modal-content{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-content input{
    align-items: center;
    background-color: var(--main-color-3) !important;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    color: var(--main-color-1) !important;
    cursor: pointer;
    display: flex;
    font-family: A,sans-serif;
    font-size: 16px;
    height: 45px;
    justify-content: center;

    outline: none;
    padding: 20px 20px 20px 20px;
    position: relative;
    margin: 0 auto;
    width: 100%;
}
.google-login-btn svg{
    /*background-color: white;*/
    justify-content: flex-start;
    padding: 12px;
    border-radius: 10px;
    margin-right: 0px;
    width: 47px;
    height: 50px;

}
.google-login-btn {
    display: flex;
    align-items: center;
    border: none;
    background-color: #fff; /* Цвет фона кнопки */
    border-radius: 10px; /* Скругление углов */
    box-shadow: 0 4px 10px rgba(255, 255, 255, -0.8), 0 0 10px rgba(255, 255, 255, 0.1); /* Белое свечение */
    width: 340px;
    height: 35px;
}
.google-login-btn:hover {
    background-color: #f2f2f2; /* Немного темнее фон при наведении */
    box-shadow: 0 4px 5px rgba(255, 255, 255, 0.1), 0 0 5px rgba(255, 255, 255, 0.1); /* Увеличенное свечение */
}


.cls-111 {
    fill: #4280ef;
}

.cls-112 {
    fill: #34a353;
}

.cls-113 {
    fill: #f6b704;
}

.cls-114 {
    fill: #e54335;
}


.loyality{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 400px;
    margin-top: 20px;
}

.loyality_icon{
    width: 60px;
    margin: 5px;
    position: relative;
    height: 60px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.l1{
    background-image: url(../img/ml1.png);
}
.l2{
    background-image: url(../img/ml2.png);
}

.l3{
    background-image: url(../img/ml3.png);
}

.l4{
    background-image: url(../img/ml4.png);
}

.l5{
    background-image: url(../img/ml5.png);
}
.loay_num{
    position: absolute;
    bottom: -10px;
    background-color: var(--main-color-1) !important;
    color: var(--main-color-6) !important;
    font-size: 15px;
    width: 60px;
    height: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    font-family: "fontB", sans-serif;
    justify-content: center;
    border-radius: 10px;
}
.loyal_result{
    display: flex;
    align-items: center;



    background-color: var(--main-color-6) !important;
    border-style: solid;
    border-width: 2px;

    height: 30px;
    margin-top: 10px;
    padding: 20px 5px 20px 5px;
    border-radius: 10px;
}
.art_coin{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 5px;
    line-height: 10px;
    font-size: 10px;
}

.loyal_number{
    padding: 10px;
    display: flex;
    align-items: center;
    right: 10px;
    font-family: "fontB", sans-serif;
    color: #1a1919;
}
.loyal_status{

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 10px;
    background-color: white;
}