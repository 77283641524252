.group{
    width: 100%;
    max-width: 450px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
}

.group input,select,button{
    border-style: none;
    /*width: 100%;*/
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #121212;
    color: #fff;
    padding: 10px;
    font-size: 15px;
    font-family: "fontB", sans-serif;
    transition: all 0.3s ease;
}

.basket_button_order {
    margin-bottom: 80px;
    background-color: #ac8700;
    transform: translate(69%, -24%);
    color: #ffffff;
}


.group input:focus, .group select:focus {
    background-color: #0b0a0a; /* Меняем цвет фона при фокусе */
    border: 1px solid rgb(98, 93, 93); /* Добавляем цветную рамку при фокусе */
    outline: none; /* Убираем стандартную рамку при фокусе */
}
.group input{
    width: 100%;
}
.group_descr{
    width: 100%;
    height: auto;
    font-family: "fontB", sans-serif;
    font-size: 17px;
    margin-top: 20px;
    letter-spacing: 0.5px;
}

.popular{
    width: 100%;
    height: auto;
    font-family: "fontB", sans-serif;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;

}
.popular input{
    width: 35px!important;
    margin-left: 20px;
    margin-top: 0!important;
    height: 35px;
}