
.Bar {
    position: relative;

    z-index: 1000000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;

    outline: none !important;
    border-style: none !important;

    box-sizing: border-box;

}

.Icons {
    overflow-x: hidden;
    overflow-y: hidden !important;
    width: 100%;
    height: 45px;
    display: flex;

    align-items: center;
    margin: 0 auto;
}

a {
    outline: none !important;
    text-decoration: none !important;
    color: var(--main-color-1);
}


.Bar_container {
    z-index: 100000000;
    position: absolute;
    overflow: hidden;

    width: 100%;
    padding: 10px;
    background-color: var(--main-color-2);
    outline: none;
    text-decoration: none;

}


.hr {

    height: 2px;
    margin: 0 auto;

}

.Icon {
    overflow: hidden;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s linear;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.Icon .cls-2 {

    transition: 0.2s linear;
    stroke: var(--main-color-1) !important;
}

.Icon:hover .cls-2 {
    transition: 0.2s linear;
    stroke: var(--main-color-1) !important;
}

.IconsR {
    width: 40px;
    margin-left: 10px;
    height: 40px;
    float: right;

    overflow-y: hidden !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cls-5 {
    fill: var(--main-color-1) !important;

}


.swicher {
    margin-right: 15px;
    margin-left: auto;
}


.checkbox {

    opacity: 0;
    position: absolute;
}

.checkbox-label {
    background-color: var(--main-color-3) !important;
    width: 42px;
    height: 22px;
    border-radius: 50px;
    position: relative;
    padding: 3px;
    cursor: pointer;
    display: flex;

    justify-content: space-between;
    align-items: center;
}

.fa-moon, .fa-sun {
    width: 20px;
    height: 20px;
}

.fa-moon {
    color: var(--main-color-1) !important;
}

.fa-sun {
    color: var(--main-color-2) !important;
}

.checkbox-label .ball {

    left: 0;
    background-color: var(--main-color-2) !important;
    width: 21px;
    height: 21px;
    position: absolute;
    border-radius: 50%;
    transition: transform 0.2s linear;
}


.checkbox:checked + .checkbox-label .ball {
    transform: translateX(22px);
}




.Burger {
    width: 35px;
    overflow: hidden;
    z-index: 30000000200;
    height: 35px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.burgerICO {
    overflow: hidden;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    float: right;
    transition: all 0.4s ease-out;
    display: inline-flex;
    cursor: pointer;
    width: 34px;
    height: 34px;
    text-align: center;
    align-items: center;
    border-radius: 5px;
}

.ham {
    overflow-y: hidden !important;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}


.line {
    pointer-events: none;
    fill: none;
    transition: 0.5s, stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: var(--main-color-1) !important;
    stroke-width: 7;
    stroke-linecap: round;
}

.ham1.active .line {
    stroke: var(--main-color-1) !important;

}

.ham1 .line {
    stroke: var(--main-color-1) !important;

}

.ham:hover .line {
    stroke: var(--main-color-1) !important;
}


/* */
.ham1 .top {
    stroke-dasharray: 40 134;
    stroke-dashoffset: 12px;
}

/* */
.ham1 .bottom {
    stroke-dasharray: 40 180;
    stroke-dashoffset: 20px;
}

/* */
.ham1.active .top {
    stroke-dashoffset: -98px;
}

/* */
.ham1.active .bottom {
    stroke-dashoffset: -138px;

}

.hide {
    display: none;
}

.Burger.active html {
    display: flex;
}

.Panel .bassic_ul {

    display: flex;
    flex-direction: column !important;
    text-align: left !important;

}



.Panel_content {
    background-color: var(--main-color-2);
    width: 100%;
    font-family: "fontS", sans-serif;
}
.Panel p{
    font-family: "fontR", sans-serif;
    text-align: center;
    margin: 0 auto;
    color: var(--main-color-0);
    font-size: 25px;

}
.Panel {
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 100px;
    padding-top: 30px;

    z-index: 1000000000;
    text-align: left !important;
    flex-direction: column;
    margin: 0 auto !important;
    transition-duration: 0.5s;
    top: 0;
    left: 0;
    right: 0;
    display: flex;

    width: 100%;
    height: 100vh;
    background-color: var(--main-color-2);




    align-self: center;

    vertical-align: center;


}
.Panel .product-card{
    width: 100% !important;
    max-width: 400px!important;
}
.Panel .product-price{
    width: 100% !important;
    float: left!important;
    text-align: left!important;
    justify-content: start!important;
    margin-left: 0!important;
}
.Panel .basket{
    width: 50px;
    height: 50px;

}
.lang select:valid{
    background-color:  var(--main-color-2)!important;
}

.Panel ul {

    width: 100%;
    height: auto;
    text-align: center !important;

}

.Panel li {
    text-decoration: none !important;
    list-style: none;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    width: auto;
    height: auto;
    font-weight: 200;
    font-size: 25px;
    color: var(--main-color-1) !important;
    text-align: center !important;
}


.lang img{
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%,-50%);
    z-index: 100000;
    pointer-events:none;
}

.logo {
    font-size: 30px;
    font-family: "fontA", sans-serif;
    font-weight: 700;
    width: auto;
    height: 35px;
    display: flex;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    justify-content: center;
}
.flag{
    width: 100%;
    height: 100%;
    border-radius: 2px;
}


.lang {
    border-radius: 5px;
    width: 30px;
    height: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}


.lang select {

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 15px;

    font-weight: 500;
    display: flex;
    text-align: center;
    align-items: center;

    justify-content: center;



    color: var(--main-color-1) !important;
    border-style: none;
    font-family: "fontR", sans-serif;

    margin-left: 15px;
    width: 40px;

    padding: 2px;
    height: 26px;



}


.language-dropdown{

    color: var(--main-color-1) !important;
    width: 19px;
    height: 19px;

    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%,-50%);
}


