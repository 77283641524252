
.menu-crm {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    justify-content: center;

}
.crm_container{
    background-color: white;
    width: 100%;
    gap: 15px;
    padding-top: 30px;
    height: auto;
    display: block;
    flex-wrap: wrap;

    padding-bottom: 50px;
    justify-content: center;


}
.crm_container .dopFilterSearch{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;


    /*max-width: 1200px!important;*/

    margin-bottom: 20px!important;
}
.product_crm {
    color: #1a1919!important;
    overflow: hidden;
    border-radius: 10px;
    background-color: white;
    margin: 15px;
    width: 100%;
    display: flex;
    height: 100%;
    max-height: 158px;
    max-width: 400px;
    border-style: solid;
    border-width: 2px;
}
.product_crm_card {
    overflow: hidden;

    color: #1a1919!important;
    padding: 4px;
    border-radius: 10px;

    height: auto;
    margin: 5px;

    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 220px;


}
.crm_icons{
    float: right;
    margin-right: 10px;
    margin-left: auto;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 100px;
    flex-direction: column;

}
.crm_icon{
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 45px;
    background-color: #1a1919;
    color: white;
    margin-bottom: 10px;
}

.product_crm_id {
    font-size: 15px;
    font-family: "fontB", sans-serif;


}
.Description-change{
    display: flex;
    justify-content: center;
}
.padtop{
    margin-top: 40px!important;
}
.Add_btn{
    font-size: 20px;
    font-family: "fontB", sans-serif;
    height: 60px;
    transition: background-color 0.3s ease, transform 0.1s ease;
}
.Add_btn:active {
    background-color: rgba(240, 240, 240, 0.33); /* Задайте нужный цвет подсветки */
    transform: scale(0.98); /* Эффект уменьшения при нажатии */
}
.Add_btn:hover {
    background-color: rgba(240, 240, 240, 0.33); /* Цвет при наведении */
    transform: scale(1.05); /* Легкое увеличение при наведении */
}

.product_crm_card_img{
    width: 145px;
    height: 100%;
    object-fit: cover;
}

.zcrmp{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.zcrmp .product_price_text{
    width: 100% !important;
}
.product_crm_title {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    font-size: 20px;
    width: auto;

    margin-top: 10px;
    font-family: "fontB", sans-serif;
}
.add_item{
    margin-left: 10px;
    border-style: solid;
    border-width: 2px;
    width: 100%;
    color: #1a1919;
    max-width: 150px !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "fontB", sans-serif;
    border-radius: 10px;
    height: 50px;
}
.search-inputs{
    border-style: solid;
    border-width: 2px;
    width: 100%;
    color: #1a1919;

    background-color: white;
    display: flex;
    padding-left: 20px;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
    font-family: "fontB", sans-serif;
    border-radius: 10px;
    font-size: 15px;
    height: 50px;
}


.product_crm_description {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    font-size: 15px;
    width: auto;


    margin-top: 10px;
    font-family: "fontI", sans-serif;
}
.products_back_button{
    background-color: #121111;
    color:  #ffffff;
    display: flex;
    align-items: center;

    width: 90px;
    height: 50px;
    border-radius: 10px;
    font-family: "fontB", sans-serif;
}
.p10{
    padding: 10px!important;
}



.product_crm_price {
    max-width: 120px;
    margin-top: 10px;
    margin-right: 10px;
    font-family: "fontB", sans-serif;
    font-size: 25px;

    width: auto;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1919;
    color: white;
}
.company-tabs {
    /*flex-wrap: wrap;*/
    justify-content: center;
    display: flex;
    gap: 20px;
    padding: 10px;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory;
}
/* Добавляем стиль для фильтра сортировки */
.price-sort {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price-sort label {
    font-size: 16px;
    font-weight: 600;
}

.price-sort select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #efefef;
    color: #0b0a0a;
    cursor: pointer;
    margin-left: 12px;
}

@media (max-width: 778px) {
    .company-tabs{
        justify-content: start;
    }
}
.company-tabs button {
    scroll-snap-align: start; /* Каждая кнопка будет "прилипать" к левому краю */
}
.company-tabs::-webkit-scrollbar {
    height: 6px; /* Высота скроллбара */
}

.company-tabs::-webkit-scrollbar-thumb {
    background: #888; /* Цвет ползунка */
    border-radius: 4px;
}
/*.company-tabs::-webkit-scrollbar {*/
/*    display: none; !* Скрываем скроллбар в Chrome/Safari *!*/
/*}*/

.tab-button {
    padding: 10px 20px;
    border: none;
    background: #efefef;
    color: black;
    cursor: pointer;
    transition: 0.3s;
    flex: 0 0 auto; /* Чтобы кнопки не сжимались */
    font-size: 18px;
}
.crm-logout-button {
    background-color: #efefef;
    margin-top: 50px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    padding: 9px 20px;
    color: #0b0a0a;
    border: 1px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.crm-logout-button:hover {
    background-color: #1a84ef; /* Цвет при наведении */
    transform: scale(1.001); /* Легкое увеличение */
}




.tab-button.active {
    background: #007bff;
    color: white;
    font-weight: bold;
}
.crm-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    color: #000000;

    font-weight: bold;
    font-size: 33px;
    margin-bottom: 22px;
    margin-top: 50px;
}

.crm-header span {
    background-color: #efefef; /* Цвет фона */
    color: #121111; /* Цвет текста */
    padding: 22px 10px; /* Отступы вокруг текста */
    border-radius: 5px; /* Закругление краев */
    display: inline-block; /* Чтобы фон был только под текстом */
}


.crm-title {
    font-size: 35px;
}

.crm-version {
    font-size: 18px;
    margin-left:262px;
    color: #0b0a0a; /* Серый цвет текста */
    font-weight: bold;
    margin-top: -20px; /* Отступ сверху */
    text-align: center; /* Выравнивание по центру */
}
.subcategory-product{
    padding:3px;
}
.subcategory-title{
    font-size: 1.5em;
    text-align:center;
}