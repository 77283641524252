
.FAQ {

    width: 100%;
    height: 100%;
    padding: 15px;
    font-size: 15px;
}

.content {
    width: 100%;
    height: 100%;
}

.content p {
    color: white;
    font-size: 14px;
    font-family: "fontR", sans-serif;

}
.column{
    margin: 20px;
}
.FAQ_selection {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100% !important;
    padding: 20px !important;
}


details {
    width: 100%;
    max-width: 500px;
    padding-bottom: 1rem;
    border-bottom: 1px solid lightgrey;
}

details * {
    max-width: 65ch;
}

summary {
    color: var(--main-color-1) !important;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
    font-family: fontR, sans-serif;
    cursor: pointer;
    list-style-position: outside;
}

summary::marker {

    content: "★ ";
    font-family: "fontD", sans-serif;
    font-size: 20px;

    color: white;
}

details[open] summary::marker {
    content: "★ ";
    font-family: "fontD", sans-serif;
    font-size: 20px;
}
