.password-container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
    color: #e1e1e1;
  background-color: #0b0a0a;
}


.gif {
    width: 200px;
    height: auto;
  margin: 23px 0;
}
.enter-Login .h2{
    color: white;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 350px; /* Ширина контейнера */
  padding: 20px;


}
.login-form h2{
  margin-right: 45px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #425d7c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}
